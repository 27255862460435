import React from "react";

import { is } from "../utils";
import styles from "./styles.module.css";
import { observer } from "../store";

type Props = {
  url: string;
  delay?: number;
  title?: string;
  refreshTime?: number;
};

function getIFrameUrl(props: Props) {
  const { url, delay, refreshTime } = props;
  if (!url) return url;
  const { protocol, host, pathname } = new URL(url);
  const resolvedProtocol = is("dev") ? "http:" : protocol;
  //const refreshTime2 = Math.max(this.state.refreshTime, refreshTime || 0);

  switch (host) {
    case "docs.google.com": {
      let path = pathname;
      if (path.endsWith("/pub")) {
        path = path.substring(0, path.length - 4);
      }
      if (!path.endsWith("/embed")) {
        path += "/embed";
      }
      return `${resolvedProtocol}//${host}/${path}?start=true&loop=true&delayms=${
        (delay || 0) * 1000
      }&refreshTime=${refreshTime ?? 0}`;
    }
    default:
      return ""; // url;
  }
}

export const GoogleSlide = observer((props: Props) => {
  const iframeUrl = getIFrameUrl(props);
  console.log("URL: ", iframeUrl);
  return (
    <div className={styles.googleSlideContainer}>
      <iframe
        className={styles.googleSlideIFrame}
        title={props.title ?? "Presentation"}
        src={iframeUrl}
      />
    </div>
  );
});
