import type {
  PouleMatches,
  Match,
  Club,
  Group,
  Semester,
  PouleStandings,
} from "./types";
import moment from "moment";

export type TTAppMatchStatus = "past" | "live" | "future";

const VERSION: string = "328";
const DEBUG = false;
const ENABLE_CACHE = false;
const MAX_REQUEST_BATCH_COUNT = 21;
const MAX_REQUEST_BATCH_WAIT_TIME = 10000;

/* Reserve engineered from ttapp */
function decodeGDResponse(_0x3e8d2f: any) {
  function decode(_0x3e8d2f: any) {
    return decodeURIComponent(
      Array["prototype"]["map"]
        ["call"](atob(_0x3e8d2f["replace"](/\s/g, "")), function (_0x3e8d2f) {
          return (
            "%" +
            ("00" + _0x3e8d2f["charCodeAt"](0x0)["toString"](0x10))["slice"](
              -0x2
            )
          );
        })
        ["join"]("")
    );
  }
  const _0x510d78 = _0x3e8d2f["match"](/^(.)(.)/);
  return decode(
    _0x3e8d2f["replace"](new RegExp(_0x510d78[0x2], "g"), "@")
      ["replace"](/A/g, _0x510d78[0x2])
      ["replace"](/@/g, "A")
      ["replace"](new RegExp(_0x510d78[0x1], "g"), "@")
      ["replace"](/e/g, _0x510d78[0x1])
      ["replace"](/@/g, "e")
      ["replace"](/^../, "ey")
  );
}

class InvalidVersionError extends Error {
  public readonly version: string;
  constructor(message: string, version: string) {
    super(message);
    this.version = version;
  }
}

class InvalidStatusError extends Error {
  public readonly status: number;
  constructor(message: string, status: number) {
    super(message);
    this.status = status;
  }
}

export class TTAppAPI {
  _version: string = VERSION;
  _token: any = undefined;
  _uid = undefined;
  _tokenCorrection = 0;
  private requestBatchCount = 0;
  private lastRequestTime = 0;
  private pendingRequestsCount = 0;

  async requestRaw(fields: any, isDebug: boolean = DEBUG) {
    const cacheKey = JSON.stringify(fields);
    const cache = localStorage.getItem(cacheKey);
    if (cache && ENABLE_CACHE) {
      const json = JSON.parse(cache);
      if (isDebug) console.debug(json);
      return json;
    }

    fields.c = `site-${this._version}`;
    if (this._uid) fields.uid = this._uid;
    if (this._token) {
      var t = (
        "" +
        Math.floor(
          (new Date().getTime() + this._tokenCorrection) / 1e3 + 483058413
        )
      ).split("");
      fields.token =
        "" +
        t[4] +
        t[9] +
        t[5] +
        t[2] +
        Math.floor(10 * Math.random()) +
        t[3] +
        t[0] +
        t[6] +
        t[1] +
        t[7] +
        t[8] +
        Math.floor(1e3 * Math.random());
    }

    const body = JSON.stringify(fields);
    if (isDebug) console.log(`FETCHing ${body}...`);
    try {
      const response = await fetch("https://ttapp.nl/api", {
        method: "post",
        body,
      });
      if (response.status !== 200)
        throw new InvalidStatusError(
          `Invalid status: ${response.status}`,
          response.status
        );
      if (isDebug) console.debug(`FETCHing DONE (status: ${response.status})`);
      const encodedJson = await response.json();
      if (encodedJson.useversion && !encodedJson.gd) {
        throw new InvalidVersionError(
          `Invalid TTApp response: useversion = ${encodedJson.useversion}`,
          `${encodedJson.useversion}`
        );
      }
      if (isDebug)
        console.debug(
          `FETCHing DONE (raw: ${JSON.stringify(encodedJson, undefined, 2)})`
        );
      const json = JSON.parse(decodeGDResponse(encodedJson.gd));
      if (isDebug)
        console.debug(
          `FETCHing DONE (decoded: ${JSON.stringify(json, undefined, 2)})`
        );
      localStorage.setItem(cacheKey, JSON.stringify(json));
      //console.log(json);
      return json;
    } catch (err: any) {
      console.log("ERR: ", err);
      console.log(`FETCH FAILED (${err.message})`);
      throw err;
    }
  }

  async request(fields: any, isDebug: boolean = DEBUG) {
    for (let i = 0; i < 1000; i++) {
      const now = Date.now();
      if (this.lastRequestTime < now - MAX_REQUEST_BATCH_WAIT_TIME) {
        // console.debug("RESETING BATCH COUNT");
        this.requestBatchCount = 0;
      }
      if (this.requestBatchCount < MAX_REQUEST_BATCH_COUNT) {
        this.requestBatchCount += 1;
        this.lastRequestTime = now;
        // console.debug("EXECUTING REQUEST");
        const result = await this.requestRaw(fields, isDebug);
        this.lastRequestTime = Math.max(this.lastRequestTime, Date.now());
        return result;
      }

      this.pendingRequestsCount += 1;
      // console.debug("TOO MANY REQUESTS, WAITING", this.pendingRequestsCount);
      await new Promise((resolve) =>
        setTimeout(
          resolve,
          this.lastRequestTime +
            MAX_REQUEST_BATCH_WAIT_TIME -
            now +
            this.pendingRequestsCount
        )
      );
      this.pendingRequestsCount -= 1;
    }
  }

  /* async requestWithRetry(fields: any, isDebug: boolean = DEBUG) {
    do {
      try {
        console.log("REQUERING");
        const result = await this.request(fields, isDebug);
        return result;
      } catch (err) {
        // if (err instanceof ServiceUnavaibleError) {
        console.debug(
          "TTApp reported service unavable, will wait and retry..."
        );
        await new Promise((resolve) => setTimeout(resolve, 5000));
        await this.login();
        // and continue with the loop
        // } else {
        // throw err;
        // }
      }
    } while (true);
  } */

  async login(): Promise<void> {
    try {
      const response = await this.request({
        task: "login",
      });
      this._token = response.token + "";
      this._uid = response.uid;
      this._tokenCorrection = 1e3 * this._token - new Date().getTime();
    } catch (err) {
      if (err instanceof InvalidVersionError) {
        if (this._version !== err.version) {
          this._version = err.version;
          return this.login();
        }
      }
      throw err;
    }
  }

  getTeams(
    clubId: string,
    semester: number = 0
  ): Promise<{
    club: Club;
    groups: Group[];
    semesters: Semester[];
  }> {
    if (!semester) {
      const date = new Date();
      semester = date.getFullYear() * 10 + (date.getMonth() > 6 ? 2 : 1);
    }
    return this.request({
      task: "club",
      clubid: clubId,
      tab: "t",
      semester,
    });
  }

  getPouleMatches(teamId: number): Promise<PouleMatches> {
    return this.request({
      task: "poule",
      p: teamId,
      tab: "m",
    });
  }

  getPouleStandings(teamId: number): Promise<PouleStandings> {
    return this.request({
      task: "poule",
      p: teamId,
      tab: "s",
    });
  }

  /* getMatch(matchId: string): Promise<Match> {
    return this.request({
      task: "match",
      matchid: matchId,
    });
  } */

  get currentDate(): Date | undefined {
    // return new Date('2019-03-23T03:00:00');
    return undefined; // undefined = now
  }

  getMatchStatus(match: Match): TTAppMatchStatus {
    const curtime = moment(this.currentDate).format("YYYY-MM-DD HH:mm:ss");
    const endtime = moment(match.playtime)
      .startOf("day")
      .add(1, "day")
      .add(3, "hours")
      .format("YYYY-MM-DD HH:mm:ss");
    if (curtime >= endtime) return "past";
    if (curtime >= match.playtime && match.score1! + match.score2! !== 10)
      return "live";
    return "future";
  }
}
